import axios from 'axios';
import { API_ENDPOINTS } from '../apiConfig';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL, 
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(config => {
  const token = localStorage.getItem('authToken');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
}, error => {
  return Promise.reject(error);
});

axiosInstance.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response && error.response.status === 401) {
    localStorage.removeItem('authToken');
    axiosInstance.get(API_ENDPOINTS.user.logout);
    window.location.reload();
  }
  else if (error.response) {
    console.error('Error status:', error.response.status);
    console.error('Error data:', error.response.data);
    return {status: 400, message: error.response.data};
  } else if (error.request) {
    console.error('Error request:', error);
    if (error.code === 'ERR_NETWORK') {
      console.error('Error data:', error.response.data);
    }
    return {status: 400, message: error.code};
  } else {
    console.error('Error message:', error.message);
    return {status: 400, message: error.message};
  }
  return Promise.reject(error);
});

export default axiosInstance;
