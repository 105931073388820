import * as React from "react";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { Typography } from "@mui/material";
import Divider from '@mui/material/Divider';
import Header from "./components/Header";
import { connect } from "react-redux";
import headerBackgroundImage  from "assets/images/info.png";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DownloadIcon from '@mui/icons-material/Download';
import IconButton from '@mui/material/IconButton';
import { useMaterialUIController } from "context";
import { loadUser } from "api/auth";
import { API_ENDPOINTS } from "apiConfig";
import axiosHelper from '../../Utilities/axiosHelper';
import MDSnackbar from "components/MDSnackbar";

function DownloadTutorial({ user, loadUser }) {

  React.useEffect(() => {
    getVersions();
    if (user === null) {
      loadUser();
    }
  },[loadUser, user]);
  const [successSB, setSuccessSB] =  React.useState(false);
  const [errorSB, setErrorSB] =  React.useState(false);
  const [content, setContent] =  React.useState("");

  const closeSuccessSB = () => setSuccessSB(false);
  const closeErrorSB = () => setErrorSB(false);

  const renderSuccessSB = (
      <MDSnackbar
        color='success'
        icon='check'
        title='Admin'
        content={content}
        open={successSB}
        onClose={closeSuccessSB}
        close={closeSuccessSB}
        bgWhite
      />
    );
  
    const renderErrorSB = (
      <MDSnackbar
        color='error'
        icon='warning'
        title='Admin'
        content={content}
        open={errorSB}
        onClose={closeErrorSB}
        close={closeErrorSB}
        bgWhite
      />
    );

  const [version, setVersion] = React.useState({
    "id": "",
    "metaTrader4MasterVersion": 1,
    "metaTrader4CopyCatVersion": 1,
    "metaTrader5MasterVersion": 1,
    "metaTrader5MCopyCatVersion": 0
  });
  
  const getVersions = async() => {
    let res = await axiosHelper.get(API_ENDPOINTS.health.downloadTutorial);
    if (res.status === 200) {
      setVersion(res.data);
    }else{
      setContent("Error getting Download Versions");
      setErrorSB(true);
    }
  }

	const [controller] = useMaterialUIController();
	const {
		darkMode,
	} = controller;

  const style = React.useMemo(() => {
    const commonStyle = {
      p: 3,
      width: '100%',
      maxWidth: 560,
      borderRadius: 2,
      border: '2px solid',
  
    };
  
    return {
      ...commonStyle,
      borderColor: darkMode ? 'white' : 'divider',
    };
  }, [darkMode]);
  
  const textColor = React.useMemo(()=> {
    if (darkMode) {
      return '#ffffff';
    }else{
      return 'black';
    }
  }, [darkMode]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      {renderSuccessSB}
      {renderErrorSB}
      <Header backgroundImage={headerBackgroundImage}>
        <MDBox mt={5} mb={3}>
          <div className="md:grid grid-cols-11 px-5 md:pb-16 lg:pb-5">
            <div className="col-span-5 space-y-[50px]">
              <div>
                <div className='justify-start'> 
                  <Typography id="transition-modal-title" variant="h3" sx={{ textDecoration: 'underline', fontWeight: 100, marginBottom: 2 }}>
                    <p className="lg:text-[32px] text-[25px]">Tutorials</p>
                  </Typography>
                </div>
                <div className="md:h-[300px] h-[200px] lg:h-[400px]">
                  <Typography variant="h6" sx={{ marginBottom: 1, fontSize: '1.55rem' }}>
                    <p className="lg:text-[24px] text-[19px]">How to set up public accounts(3rdPartyTrading pt1)</p>
                  </Typography>
                  <iframe className="w-full lg:h-full md:h-[300px] h-[200px]" src="https://www.youtube.com/embed/49Q-4TT-F0w?si=MGkcXLQrSDqZDpAb" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
              </div>
              <div>
                <div className="md:h-[300px] h-[200px] lg:h-[400px]">
                  <Typography variant="h6" sx={{ marginBottom: 1, fontSize: '1.55rem' }}>
                    <p className="lg:text-[24px] text-[19px] mt-[80px]">How to subscribe to public accounts (3rd party trading pt2)</p>
                  </Typography>
                  <iframe className="w-full lg:h-full md:h-[300px] h-[200px]" src="https://www.youtube.com/embed/kstWsHSjumE?si=j9iUNoS6f32cG5KB" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
              </div>
              <div>
                <div className="md:h-[300px] h-[200px] lg:h-[400px]">
                  <Typography variant="h6" sx={{ marginBottom: 1, fontSize: '1.55rem' }}>
                    <p className="lg:text-[24px] text-[19px] mt-[80px]">New Copier Cat Features</p>
                  </Typography>
                  <iframe className="w-full lg:h-full md:h-[300px] h-[200px]" src="https://www.youtube.com/embed/FW-nzTaTdJo?si=UWJGa6rbIRYu75A2" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
              </div>
              <div>
                <div className="md:h-[300px] h-[200px] lg:h-[400px]">
                  <Typography variant="h6" sx={{ marginBottom: 1, fontSize: '1.55rem' }}>
                    <p className="lg:text-[24px] text-[19px]">How to setup TradeLocker</p>
                  </Typography>
                  <iframe className="w-full lg:h-full md:h-[300px] h-[200px]" src="https://www.youtube.com/embed/u204bWqwtpo?si=k6UvLgJ62ktEoRoP" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
              </div>
              <div>
                <div className="md:h-[300px] h-[200px] lg:h-[400px]">
                  <Typography variant="h6" sx={{ marginBottom: 1, fontSize: '1.55rem' }}>
                    <p className="lg:text-[24px] text-[19px]">How to setup Match Trader</p>
                  </Typography>
                  <iframe className="w-full lg:h-full md:h-[300px] h-[200px]" src="https://www.youtube.com/embed/etYBBOxO0lE?si=oh1aElezbjTPuJJd" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
              </div>
              <div>
                <div className="md:h-[300px] h-[200px] lg:h-[400px]">
                  <Typography variant="h6" sx={{ marginBottom: 1, fontSize: '1.55rem' }}>
                    <p className="md:text-[24px] text-[19px]">How to copy from MT4 to MT4</p>
                  </Typography>
                  <iframe className="w-full lg:h-full md:h-[300px] h-[200px]" src="https://www.youtube.com/embed/bC3MXuS_ic8?si=9ncENIpDlhKz2d-e" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
              </div>
              
              <div>
                <div className="md:h-[300px] h-[200px] lg:h-[400px]">
                  <Typography variant="h6" sx={{ marginBottom: 1, fontSize: '1.55rem' }}>
                    <p className="lg:text-[24px] text-[19px]">How to setup MT4 Master Trader</p>
                  </Typography>
                  <iframe className="w-full lg:h-full md:h-[300px] h-[200px]" src="https://www.youtube.com/embed/Vb6faDv6BcY?si=NwU8raYZy73htsPZ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
              </div>
              <div className="md:h-[300px] h-[200px] lg:h-[400px]">
                <Typography variant="h6" sx={{ marginBottom: 1, fontSize: '1.55rem' }}>
                  <p className="lg:text-[24px] text-[19px]">Intro to Copier Cat pt1</p>
                </Typography>
                <iframe className="w-full lg:h-full md:h-[300px] h-[200px]" src="https://www.youtube.com/embed/L0VpO7uGTdo?si=-z_WPBTCWG7oBWWZ" title="YouTube video player" frameborder="0" 
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                  referrerpolicy="strict-origin-when-cross-origin" allowfullscreen>
                </iframe>
              </div>

              <div className="md:h-[300px] h-[200px] lg:h-[400px]">
                <Typography variant="h6" sx={{ marginBottom: 1, fontSize: '1.55rem' }}>
                  <p className="lg:text-[24px] text-[19px]">Using Tradingview as a trade generator</p>
                </Typography>
                <iframe className="w-full lg:h-full md:h-[300px] h-[200px]" src="https://www.youtube.com/embed/L25cQQF9I3o?si=ZtB__eDoxCjJRgI2" title="YouTube video player" frameborder="0" 
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                  referrerpolicy="strict-origin-when-cross-origin" allowfullscreen>
                </iframe>
              </div>

            </div>

            <div className="col-span-1 mt-[90px]" style={{ textAlign: '-webkit-center' }}>
              <Divider component="li" className=" block md:hidden"/>
              <Divider orientation="vertical" className=" hidden md:block" flexItem />
            </div>
            <div className="col-span-5">
              <Typography id="transition-modal-title" variant="h3" sx={{ textDecoration: 'underline', fontWeight: 100, marginBottom: 2  }}>
                <p className="lg:text-[32px] text-[25px]">Downloads</p>
              </Typography>
              <List sx={style} aria-label="mailbox folders">
                <ListItem
                  alignItems="flex-start"
                  key={1}
                  disableGutters
                  secondaryAction={
                    user?.isSubscribed &&
                    <IconButton aria-label="comment">
                      <a href={"https://www.copiercat.com/downloads/CopierCatMasterMT4v" + version?.metaTrader4MasterVersion + ".zip"} style={{ textDecoration: 'underline', fontWeight: 100 }}>
                        <DownloadIcon sx={{ color: textColor }} />
                      </a>
                    </IconButton>
                  }
                >
                  <ListItemText primary={"MetaTrader4 Master v" + version?.metaTrader4MasterVersion}sx={{ color: textColor }} />
                </ListItem>
                <ListItem
                  alignItems="flex-start"
                  key={1}
                  disableGutters
                  secondaryAction={
                    user?.isSubscribed &&
                    <IconButton aria-label="comment">
                      <a href={"https://www.copiercat.com/downloads/CopierCatSlaveMT4v" + version?.metaTrader4CopyCatVersion + ".zip"} style={{ textDecoration: 'underline', fontWeight: 100 }}>
                        <DownloadIcon sx={{ color: textColor }} />
                      </a>
                    </IconButton>
                  }
                >
                  <ListItemText primary={"MetaTrader4 CopyCat v" + version?.metaTrader4CopyCatVersion}sx={{ color: textColor }} />
                </ListItem>
                <Divider component="li" />
                <ListItem
                  alignItems="flex-start"
                  key={1}
                  disableGutters
                  secondaryAction={
                    user?.isSubscribed &&
                    <IconButton aria-label="comment">
                      <a href={"https://www.copiercat.com/downloads/CopierCatMasterMT5v" + version?.metaTrader5MasterVersion + ".zip"} style={{ textDecoration: 'underline', fontWeight: 100 }}>
                        <DownloadIcon sx={{ color: textColor }} />
                      </a>
                    </IconButton>
                  }
                >
                  <ListItemText primary={"MetaTrader5 Master v" + version?.metaTrader5MasterVersion} sx={{ color: textColor }} />
                </ListItem>
                <Divider component="li" />
                <ListItem
                  alignItems="flex-start"
                  key={1}
                  disableGutters
                  secondaryAction={
                    user?.isSubscribed &&
                    <IconButton aria-label="comment">
                      <a href="https://www.copiercat.com/downloads/CopierCatPineScriptExample.txt" style={{ textDecoration: 'underline', fontWeight: 100 }}>
                        <DownloadIcon sx={{ color: textColor }} />
                      </a>
                    </IconButton>
                  }
                >
                  <ListItemText primary="Trading View PineScript Example" sx={{ color: textColor }} />
                </ListItem>
              </List>
            </div>
          </div>
        </MDBox>
      </Header>
      <Footer />
    </DashboardLayout>
  );
}

const mapStateToProps = (state) => ({
  serverInfo: state.infoPage?.serverInfo,
  user: state.auth?.user,
});

export default connect(mapStateToProps, { loadUser })(DownloadTutorial);
