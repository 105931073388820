import * as React from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Header from "./components/Header";
import { loadUser } from "api/auth";
import { connect } from "react-redux";

function Billing({ loadUser, user }) {

  
  React.useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/buy-button.js';
    script.async = true;
    document.body.appendChild(script);
    if (user === null) {
      loadUser();
    }

    return () => {
      document.body.removeChild(script);
    };
  }, [loadUser, user]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header>
        <MDBox mt={5} mb={3}>
          <Grid container spacing={1} className='p-4 justify-center'>
            <stripe-buy-button
              buy-button-id="buy_btn_1PGByFAtAhCRUffIOftIeK73"
              publishable-key="pk_test_51P9YiOAtAhCRUffIfzSSVgStbmyrZzCrBYpBHPsBLfDuZeKhjm8XNJRbhlCM6SuYvtFS6xCQbw56o4GE2X1wmI1m00hg1GOzer"
            >
            </stripe-buy-button>
          </Grid>
        </MDBox>
      </Header>
      <Footer />
    </DashboardLayout>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth?.user,
});

export default connect(mapStateToProps, {loadUser})(Billing);
