import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import {connect} from "react-redux";
import { getUsers } from '../../../api/admin';
import { useMaterialUIController } from "context";
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { makeStyles } from '@mui/styles';
import MDSnackbar from "components/MDSnackbar";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Grid from "@mui/material/Grid";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import MDBox from "components/MDBox";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import RoleModal from './RoleModal';
import TokenModal from './TokenModal';
import { API_ENDPOINTS } from "../../../apiConfig";
import axiosHelper from "../../../Utilities/axiosHelper";
const useStyles = makeStyles((theme) => ({

  root: {
    color: '#ffffff',
    backgroundColor: '#1a2035',
  },
  actions: {
     color: '#ffffff', 
  },
}));

const useStylesConfirm = makeStyles((theme) => ({
  dialogDark: {
    backgroundColor: 'black', 
  },
  dialogWhite: {
    backgroundColor: 'white', 
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'userId',
    numeric: false,
    disablePadding: true,
    label: 'User ID',
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: true,
    label: 'Email',
  },
  {
    id: 'subscriptionPaid',
    numeric: false,
    disablePadding: false,
    label: 'Subscribed',
  },
  {
    id: 'isLockedOut',
    numeric: false,
    disablePadding: false,
    label: 'Is Locked Out',
  },
  {
    id: 'created',
    numeric: false,
    disablePadding: false,
    label: 'Created Date',
  },
  {
    id: 'lastLoginDate',
    numeric: false,
    disablePadding: false,
    label: 'Last Login Date',
  },
  {
    id: 'roles',
    numeric: false,
    disablePadding: false,
    label: 'Roles',
  },
  {
    id: 'action',
    numeric: false,
    disablePadding: false,
    label: '',
  },
];

function EnhancedTableHead(props) {
  const {  order, orderBy, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  
  const [infoSB, setInfoSB] = React.useState(false);
  const closeInfoSB = () => setInfoSB(false);
  const renderInfoSB = (
    <MDSnackbar
      icon='notifications'
      title='TimeStamp'
      content={"Timestamp is shown in your current timezone"}
      open={infoSB}
      onClose={closeInfoSB}
      close={closeInfoSB}
    />
  );

  return (
    <TableHead style={{ display: 'contents' }}>
    {renderInfoSB}
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'center' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
            >
              <Typography variant='h6' style={{ fontSize: 'large' }}
              onClick={createSortHandler(headCell.id)}>
                  {headCell.label}
              </Typography>
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

function DataTable({getUsers, user, userInfo, userData, roles}) {

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [id, setID] = React.useState(null);
  const [menu, setMenu] = React.useState(null);
  const open = Boolean(anchorEl);
  const [openRole, setOpenRole] = React.useState(false);
  const [openToken, setOpenToken] = React.useState(false);
  const [role, setRole] = React.useState(null);
  const [token, setToken] = React.useState('');
  
  const [successSB, setSuccessSB] =  React.useState(false);
  const [errorSB, setErrorSB] =  React.useState(false);
  const [content, setContent] =  React.useState("");

  const closeSuccessSB = () => setSuccessSB(false);
  const closeErrorSB = () => setErrorSB(false);
  
  const handleToken = async() => {
    let url = API_ENDPOINTS.admin.getToken + id;
    let res = await axiosHelper.get(url);
    if (res.status === 200 || res.status === 204) {
      setToken(res.data);
      setOpenToken(true);
    }else{
      setContent("Failed ! Please try it again !");
      setErrorSB(true);
    }
  }

  const renderSuccessSB = (
    <MDSnackbar
      color='success'
      icon='check'
      title='Admin'
      content={content}
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  const renderErrorSB = (
    <MDSnackbar
      color='error'
      icon='warning'
      title='Admin'
      content={content}
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );
  
  const [openDia, setOpenDia] = React.useState(false);

  const openDialoag = (value) => {
    setMenu(value);
    handleClose();
    setOpenDia(true);
  };

  const closeDialog = () => {
    setMenu(null);
    setID(null);
    setOpenDia(false);
  };

  const lockUser = async() => {
    let url = API_ENDPOINTS.admin.lock + "?userId=" + id;
    let res = await axiosHelper.get(url);
    if (res.status === 200) {
      setContent("This user has been locked out");
      setSuccessSB(true);
      closeDialog();
      getUsers();
    }else{
      setContent(res.message);
      setErrorSB(true);
      closeDialog();
    }
  }

  const addRole = async() => {
    let url = API_ENDPOINTS.admin.updateRole + "?userId=" + id + "&role=" + role + "&addRole=true";
    let res = await axiosHelper.post(url);
    if (res.status === 200) {
      setContent("This role has been added");
      setSuccessSB(true);
      setOpenDia(false);
      setOpenRole(false);
      setMenu(null);
      setRole(null);
      setID(null);
      getUsers();
    }else{
      setContent(res.message);
      setErrorSB(true);
      setOpenDia(false);
      setOpenRole(false);
      setMenu(null);
      setRole(null);
      setID(null);
    }
  }

  const removeRole = async() => {
    let url = API_ENDPOINTS.admin.updateRole + "?userId=" + id + "&role=" + role + "&addRole=false";
    let res = await axiosHelper.post(url);
    if (res.status === 200) {
      setContent("This role has been removed");
      setSuccessSB(true);
      setOpenDia(false);
      setOpenRole(false);
      setMenu(null);
      setRole(null);
      setID(null);
      getUsers();
    }else{
      setContent(res.message);
      setErrorSB(true);
      setOpenDia(false);
      setOpenRole(false);
      setMenu(null);
      setRole(null);
      setID(null);
    }
  }

  const handleUser = () => {
    if (menu === 1) {
      lockUser();
    }else if (menu === 2) {
      removeRole();
    }else if (menu === 3) {
      addRole();
    }
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(()=>{
    if (user?.isSubscribed) {
      getUsers();
    }
  }, [getUsers, user])
  
	const [controller] = useMaterialUIController();
	const {
		darkMode,
	} = controller;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  
  const visibleRows = React.useMemo(
    () =>
      stableSort(userInfo, getComparator(order, orderBy)),
    [order, orderBy, userInfo],
  );

  const hasNextPage = React.useMemo(() => {
    if (userInfo.length < 20) {
      return true
    }
 }, [userInfo]);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const classes = useStyles();
  const classesConfirm = useStylesConfirm();

  return (
    <Box sx={{ width: '100%', borderRadius: 4, overflow: "hidden" }}>
      {renderSuccessSB}
      {renderErrorSB}
      <RoleModal open={openRole} setOpen={setOpenRole} id={id} role={role} setRole={setRole} setOpenDia={setOpenDia} setMenu={setMenu} />
      <TokenModal open={openToken} setOpen={setOpenToken} token={token} />
      <Dialog
        open={openDia}
        TransitionComponent={Transition}
        keepMounted
        onClose={closeDialog}
        classes={{ paper: darkMode? classesConfirm.dialogDark : classesConfirm.dialogWhite }}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle style={{ color: darkMode? "white" : "black" }}>Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you really updating ?
            Please give me this content !
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>Disagree</Button>
          <Button onClick={handleUser}>Agree</Button>
        </DialogActions>
      </Dialog>
      <Grid container spacing={3} sx={{ marginTop: 0 }}>
        <Grid item xs={12} md={6} lg={4}>
          <MDBox mb={1.5}>
            <ComplexStatisticsCard
              color="dark"
              icon='person'
              title="Total Users"
              count={userData?.totalUsers}
            />
          </MDBox>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <MDBox mb={1.5}>
            <ComplexStatisticsCard
              icon="person"
              title="Subscribed Users"
              count={userData?.totalSubscribedUsers}
            />
          </MDBox>
        </Grid>
      </Grid>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => {openDialoag(1)}}>Lock User</MenuItem>
        <MenuItem onClick={() => {setOpenRole(true)}}>Update User Role</MenuItem>
        <MenuItem onClick={() => {
          handleToken();
        }}>
            Get Token
        </MenuItem>
      </Menu>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer sx={{ boxShadow: "none" }}>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
          >
          <TableRow
            hover
            tabIndex={-1}
            key={1}
            sx={{ cursor: 'pointer' }}
          >
            <TableCell
              component="th"
              scope="row"
              padding="checkbox"
              colSpan={7}
              style={{ fontSize: 'larger', fontWeight: 'bolder' }}
              align='left'
              >
              <Typography
                sx={{ flex: '1 1 100%', fontWeight: '1500' }}
                variant="h4"
                id="tableTitle"
                component="div"
              >
                Users
              </Typography>
            </TableCell>
          </TableRow> 
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {visibleRows.length !== 0 ?
                visibleRows.map((row, index) => {
                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={index}
                      sx={{ cursor: 'pointer' }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        padding="none"
                        >
                        <Typography variant='h6' style={{ fontSize: 'large' }}>
                            {row.userId}
                        </Typography>
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        padding="none"
                        >
                        <Typography variant='h6' style={{ fontSize: 'large' }}>
                            {row.email}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography variant='h6' style={{ fontSize: 'large' }}>
                            {String(row.subscriptionPaid)}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography variant='h6' style={{ fontSize: 'large' }}>
                            {String(row.isLockedOut)}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography variant='h6' style={{ fontSize: 'large' }}>
                            {row.created}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography variant='h6' style={{ fontSize: 'large' }}>
                            {row.lastLoginDate}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography variant='h6' style={{ fontSize: 'large' }}>
                            {row.roles.map((item, index)=> {
                              let role = "";
                              if (index !== 0) {
                                role += ",";
                              }
                              return role += roles[item];
                            })}
                        </Typography>
                      </TableCell>
                      <TableCell align="left"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={()=> {
                          setID(row.userId);
                        }}
                      >
                        <Typography variant='h6' style={{ fontSize: 'large' }} onClick={handleClick}>
                            <MoreHorizIcon sx={{ width: '35px', height: '35px' }}/>
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })
                :
                <TableRow
                  hover
                  tabIndex={-1}
                  key={1}
                  sx={{ cursor: 'pointer' }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    padding="none"
                    colSpan={7}
                    style={{ fontSize: 'larger', fontWeight: 'bolder' }}
                    align='center'
                    >
                    <Typography
                      sx={{ flex: '1 1 100%', fontWeight: '1500' }}
                      variant="h4"
                      id="tableTitle"
                      component="div"
                    >
                      No trades have been copied yet
                    </Typography>
                  </TableCell>
                </TableRow>         
            }
            </TableBody>
          </Table>
        </TableContainer>
        {visibleRows.length !== 0 ?
          (darkMode?
            <TablePagination
              rowsPerPageOptions={[10, 20, 25]}
              component="div"
              count={-1}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              nextIconButtonProps={{ disabled: hasNextPage }}
              sx={{
                  color: '#ffffff',
              }}
              classes={{
                root: classes.root,
                actions: classes.actions,
              }}
            />
            :
            <TablePagination
              rowsPerPageOptions={[10, 20, 25]}
              component="div"
              count={-1}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              nextIconButtonProps={{ disabled: hasNextPage }} 
            />
          )
          :
          (darkMode?
            <TablePagination
              rowsPerPageOptions={[10, 20, 25]}
              component="div"
              count={0}
              rowsPerPage={rowsPerPage}
              page={0}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              nextIconButtonProps={{ disabled: true }}
              sx={{
                  color: '#ffffff',
              }}
              classes={{
                root: classes.root,
                actions: classes.actions,
              }}
            />
            :
            <TablePagination
              rowsPerPageOptions={[10, 20, 25]}
              component="div"
              count={0}
              rowsPerPage={rowsPerPage}
              page={0}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              nextIconButtonProps={{ disabled: true }}
            />
          )
      }
      </Paper>
    </Box>
  );
}

const mapStateToProps = (state)=> ({
  userInfo: state.admin?.users,
  user: state.auth.user,
  userData: state.admin?.userData,
  roles: state.admin?.roles
});

export default connect(mapStateToProps, {getUsers})(DataTable);