import React from "react";
import PropTypes from "prop-types";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";
import linkIcon from "../../assets/images/link.png";
import MDBox from "components/MDBox";
import { connect } from "react-redux";
import axiosHelper from "../../Utilities/axiosHelper";
import { API_ENDPOINTS } from "apiConfig";
import MDSnackbar from "components/MDSnackbar";
import "./index.css";
import {
  collapseItem,
  collapseIconBox,
  collapseIcon,
  collapseText,
} from "examples/Sidenav/styles/sidenavCollapse";
import { loadUser } from "api/auth";

import { useMaterialUIController,
  setMiniSidenav
 } from "context";

function SidenavCollapse({ user, loadUser, icon, name, active, ...rest }) {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor } = controller;
  const [errorSB, setErrorSB] =  React.useState(false);
  const [content, setContent] =  React.useState("");

  const closeErrorSB = () => setErrorSB(false);
  
  const onClick = () => {
    if (window.innerWidth < 1200) {
      setMiniSidenav(dispatch, !miniSidenav);
    }
  }

  const iconColor = React.useMemo(()=> {
    if (name === 'Tutorials/Downloads' && user.hasNewContent) {
      return '#ff7300';
    }else{
      return null;
    }
  }, [name, user.hasNewContent])

  
  const renderErrorSB = (
    <MDSnackbar
      color='error'
      icon='warning'
      title='Admin'
      content={content}
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );

  const setNewContent = async() => {
    let res = await axiosHelper.put(API_ENDPOINTS.profileEndpoints.setNewContent, { hasNewContent: false });
    if (res.status === 200 || res.status === 204) {
      loadUser();
    }else{
      setContent(res.message);
      setErrorSB(true);
    }
  }

  return (
    <ListItem component="li" onClick={onClick}>
      {renderErrorSB}
      <MDBox
        {...rest}
        sx={(theme) =>
          collapseItem(theme, {
            active,
            transparentSidenav,
            whiteSidenav,
            darkMode,
            sidenavColor,
          })
        }
      >
        <ListItemIcon
          style={{ color: iconColor }}
          sx={(theme) =>
            collapseIconBox(theme, { transparentSidenav, whiteSidenav, darkMode, active })
          }
        >
          {name !== 'Trade Link'?
            typeof icon === "string" ? (
              <Icon sx={(theme) => collapseIcon(theme, { active })}>{icon}</Icon>
            ) : (
              icon
            )
            :
            <img src={linkIcon} alt="" style={{ width: 20, height: 20 }}/> 
         }
        </ListItemIcon>
         {
            !(name === 'Tutorials/Downloads' && user.hasNewContent)?
              <ListItemText
                primary={name}
                sx={(theme) =>
                  collapseText(theme, {
                    miniSidenav,
                    transparentSidenav,
                    whiteSidenav,
                    active,
                  })
                }
                style={{ color: iconColor, fontWeight: "bolder" }}
              />
              :
              <p className="Blazing" contenteditable="true" onClick={setNewContent}>{name}</p>
         }
      </MDBox>
    </ListItem>
  );
}

SidenavCollapse.defaultProps = {
  active: false,
};

SidenavCollapse.propTypes = {
  name: PropTypes.string.isRequired,
  active: PropTypes.bool,
};


const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, { loadUser })(SidenavCollapse);
