import axiosHelper from "../Utilities/axiosHelper";
import {
  GET_PROFILES,
  PROFILE_ERROR,
  GET_BROKERINFO,
  GET_PLATFORMINFO,
  GET_PLATFORMS,
  GET_USER_PLATFORMS,
  POST_PLATFORMDATA,
  GET_PUBLICACCOUNTS
} from "./types";
import { API_ENDPOINTS } from "../apiConfig";

var brokerInfo;

const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const options = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
  hour12: true, 
  timeZone: userTimezone 
};

export const getBrokerInfo = () => async (dispatch) => {
  try {
    let response = (
      await axiosHelper.get(API_ENDPOINTS.profileEndpoints.platforms)
    );
    if (response.status === 200) {
      brokerInfo = response.data;
      let brokerOption = [];
      brokerOption.length = 0;
      brokerInfo.map((broker) => {
        brokerOption.push({ label: broker.name, value: broker.id });
        return null;
      });
      dispatch({
        type: GET_BROKERINFO,
        payload: brokerOption,
      });
    }else{
      dispatch({
        type: PROFILE_ERROR,
        payload: { msg: response.message },
      });
    }
    return response;
  } catch (err) {
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response },
    });
  }
};

export const getCurrentProfile = () => async (dispatch) => {
  try {
    var response = await axiosHelper.get(
      API_ENDPOINTS.profileEndpoints.getProfiles
    );
    if (response.status === 200) {
      var data = response.data;
      var brokerAccount = [];
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < brokerInfo.length; j++) {
          if (data[i].brokerId === brokerInfo[j].id) {
            data[i].brokerName = brokerInfo[j].name;
            brokerAccount.push(data[i]);
          }
        }
      }
      dispatch({
        type: GET_PROFILES,
        payload: brokerAccount,
      });
      return response;
    }else{
      dispatch({
        type: PROFILE_ERROR,
        payload: { msg: response.message },
      });
    }
  } catch (err) {
    localStorage.removeItem('authToken');
    window.location.reload();
  }
};
//
export const getPlatforms = () => async (dispatch) => {
  try {
    const platforms = (
      await axiosHelper.get(API_ENDPOINTS.profileEndpoints.getPlatforms)
    );
    if (platforms.status === 200) {
      dispatch({
        type: GET_PLATFORMS, 
        payload: platforms.data,
      });
    }else{
      dispatch({
        type: "PLATFORM_ERROR",
        payload: { msg: platforms.message },
      });
    }
    return platforms;
  } catch (err) {
    dispatch({
      type: "PLATFORM_ERROR",
      payload: { msg: err.response },
    });
  }
};

// actions.js
export const newPlatform = () => (dispatch) => {
  dispatch({
    type: "NEW_PLATFORM"
  });
};
 

export const getUserPlatforms = () => async (dispatch) => {
  try {
    var userPlatforms = (
      await axiosHelper.get(API_ENDPOINTS.profileEndpoints.getUserPlatforms)
    );
    if (userPlatforms.status === 200) {
      dispatch({
        type: GET_USER_PLATFORMS,
        payload: userPlatforms.data,
      });
    }else{
      dispatch({
        type: "PLATFORM_ERROR",
        payload: { msg: userPlatforms.message },
      });
    }
    return userPlatforms;
  } catch (err) {
    dispatch({
      type: "PLATFORM_ERROR",
      payload: { msg: err.response },
    });
  }
};

export const postPlatformData = (data) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    var userPlatforms = (
      await axiosHelper.post(
        API_ENDPOINTS.profileEndpoints.postPlatformData,
        data,
        config
      )
    );
    if (userPlatforms.status === 200) {
      dispatch({
        type: POST_PLATFORMDATA,
        payload: userPlatforms.data,
      });
    }
    return postPlatformData;
  } catch (err) {
    dispatch({
      type: "PLATFORM_ERROR",
      payload: { msg: err.response },
    });
  }
};

export const getPlatformInfoByID = () => async (dispatch) => {
  try {
    let platformId = 1;
    var platformInfo = (
      await axiosHelper.get(
        API_ENDPOINTS.profileEndpoints.getPlatformInfoByID + `${platformId}`
      )
    );
    if (platformInfo.status === 200) {
      dispatch({
        type: GET_PLATFORMINFO,
        payload: platformInfo.data,
      });
    }else{
      dispatch({
        type: "PLATFORM_ERROR",
        payload: { msg: platformInfo.message },
      });
    }
    return platformInfo;
  } catch (err) {
    dispatch({
      type: "PLATFORM_ERROR",
      payload: { msg: err.response },
    });
  }
};

export const getPublicAccounts = () => async (dispatch) => {
  try {
    var publicAccounts = (
      await axiosHelper.get(
        API_ENDPOINTS.profileEndpoints.publicAccounts
      )
    );
    if (publicAccounts.status === 200) {
      let temp = publicAccounts.data;
      for (let i = 0; i < temp.length; i++) {
        const date = new Date(temp[i].createdDate);
        const formattedDate = new Intl.DateTimeFormat('default', options).format(date);
        temp[i].createdDate = formattedDate;
      }
      dispatch({
        type: GET_PUBLICACCOUNTS,
        payload: temp,
      });
    }
    return publicAccounts;
  } catch (err) {
    console.log(err);
  }
}