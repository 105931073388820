import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import {connect} from "react-redux";
import { getTradeHistory } from "api/trade";
import { useMaterialUIController } from "context";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Typography from '@mui/material/Typography';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import MDSnackbar from "components/MDSnackbar";
import DetailModal from "./DetailModal";


const useStyles = makeStyles((theme) => ({
  root: {
    color: '#ffffff',
    backgroundColor: '#1a2035',
  },
  actions: {
     color: '#ffffff', 
  },
 }));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'symbol',
    numeric: false,
    disablePadding: true,
    label: 'Symbol',
  },
  {
    id: 'side',
    numeric: false,
    disablePadding: false,
    label: 'Side',
  },
  {
    id: 'qty',
    numeric: false,
    disablePadding: false,
    label: 'Qty',
  },
  {
    id: 'action',
    numeric: false,
    disablePadding: false,
    label: 'Action',
  },
  {
    id: 'tradeSent',
    numeric: false,
    disablePadding: false,
    label: 'TradeSent',
  },
  {
    id: 'trader',
    numeric: true,
    disablePadding: false,
    label: 'Trader',
  },
  {
    id: 'copyCat',
    numeric: true,
    disablePadding: false,
    label: 'Copy Cat',
  },
  {
    id: 'timeStamp',
    numeric: true,
    disablePadding: false,
    label: 'TimeStamp',
  },
  {
    id: 'detail',
    numeric: false,
    disablePadding: false,
    label: 'Details',
  },
];

function EnhancedTableHead(props) {
  const {  order, orderBy, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  
  const [infoSB, setInfoSB] = React.useState(false);
  const closeInfoSB = () => setInfoSB(false);
  const renderInfoSB = (
    <MDSnackbar
      icon='notifications'
      title='TimeStamp'
      content={"Timestamp is shown in your current timezone"}
      open={infoSB}
      onClose={closeInfoSB}
      close={closeInfoSB}
    />
  );

  const displayTip = () => {
    setInfoSB(true);
  }
  
	const [controller] = useMaterialUIController();
	const {
		darkMode,
	} = controller;
  return (
    <TableHead style={{ display: 'contents' }}>
    {renderInfoSB}
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'center' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
            >
              <Typography variant='h6' style={{ fontSize: 'large' }}
              onClick={createSortHandler(headCell.id)}>
                  {headCell.label}
              </Typography>
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
              {headCell.id === 'timeStamp' && 
                <Tooltip title="Timestamp is shown in your current timezone" placement="top">
                  <IconButton onClick={displayTip}>
                    {darkMode? 
                      <ErrorOutlineIcon sx={{ color: '#ffffff' }}/> 
                      :
                      <ErrorOutlineIcon/>
                    }
                  </IconButton>
                </Tooltip>
              }
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

function DataTable({getTradeHistory, tradeHistory, user}) {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [detail, setDetail] = React.useState({
    "uid": "",
    "brokerOrderId": null,
    "takeProfit": 0,
    "stopLoss": 0
  });
  const [openDetail, setOpenDetail] = React.useState(false);

  React.useEffect(()=>{
    if (user?.isSubscribed) {
      getTradeHistory(1);
    }
  }, [getTradeHistory, user])
  
	const [controller] = useMaterialUIController();
	const {
		darkMode,
	} = controller;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  
  const visibleRows = React.useMemo(
    () =>
      stableSort(tradeHistory, getComparator(order, orderBy)),
    [order, orderBy, tradeHistory],
  );

  const hasNextPage = React.useMemo(() => {
    if (tradeHistory.length < 20) {
      return true
    }
 }, [tradeHistory]);

  const handleChangePage = (event, newPage) => {
    if (user?.isSubscribed) {
      getTradeHistory(newPage + 1);
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const classes = useStyles();

  return (
    <Box sx={{ width: '100%', borderRadius: 4, overflow: "hidden" }}>
      <DetailModal open={openDetail} setOpen={setOpenDetail} data={detail} />
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer sx={{ boxShadow: "none" }}>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
          >
          <TableRow
            hover
            tabIndex={-1}
            key={1}
            sx={{ cursor: 'pointer' }}
          >
            <TableCell
              component="th"
              scope="row"
              padding="checkbox"
              colSpan={7}
              style={{ fontSize: 'larger', fontWeight: 'bolder' }}
              align='left'
              >
              <Typography
                sx={{ flex: '1 1 100%', fontWeight: '1500' }}
                variant="h4"
                id="tableTitle"
                component="div"
              >
                Copied Trades
              </Typography>
            </TableCell>
          </TableRow> 
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {visibleRows.length !== 0 ?
                visibleRows.map((row, index) => {
                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={index}
                      sx={{ cursor: 'pointer' }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        padding="none"
                        >
                        <Typography variant='h6' style={{ fontSize: 'large' }}>
                            {row.symbol}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography variant='h6' style={{ fontSize: 'large' }}>
                            {row.side}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography variant='h6' style={{ fontSize: 'large' }}>
                            {row.qty}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography variant='h6' style={{ fontSize: 'large' }}>
                            {row.action}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography variant='h6' style={{ fontSize: 'large' }}>
                            {row.tradeSent}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant='h6' style={{ fontSize: 'large' }}>
                            {row.trader}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant='h6' style={{ fontSize: 'large' }}>
                            {row.copyCat}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant='h6' style={{ fontSize: 'large' }}>
                            {row.timeStamp}
                        </Typography>
                      </TableCell>
                      <TableCell align="left"
                        onClick={()=> {
                          setDetail(row.details);
                          setOpenDetail(true);
                        }}
                      >
                        <Typography variant='h6' style={{ fontSize: 'large' }}>
                            <MoreHorizIcon sx={{ width: '35px', height: '35px' }}/>
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })
                :
                <TableRow
                  hover
                  tabIndex={-1}
                  key={1}
                  sx={{ cursor: 'pointer' }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    padding="none"
                    colSpan={7}
                    style={{ fontSize: 'larger', fontWeight: 'bolder' }}
                    align='center'
                    >
                    <Typography
                      sx={{ flex: '1 1 100%', fontWeight: '1500' }}
                      variant="h4"
                      id="tableTitle"
                      component="div"
                    >
                      No trades have been copied yet
                    </Typography>
                  </TableCell>
                </TableRow>         
            }
            </TableBody>
          </Table>
        </TableContainer>
        {visibleRows.length !== 0 ?
          (darkMode?
            <TablePagination
              rowsPerPageOptions={[10, 20, 25]}
              component="div"
              count={-1}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              nextIconButtonProps={{ disabled: hasNextPage }}
              sx={{
                  color: '#ffffff',
              }}
              classes={{
                root: classes.root,
                actions: classes.actions,
              }}
            />
            :
            <TablePagination
              rowsPerPageOptions={[10, 20, 25]}
              component="div"
              count={-1}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              nextIconButtonProps={{ disabled: hasNextPage }} 
            />
          )
          :
          (darkMode?
            <TablePagination
              rowsPerPageOptions={[10, 20, 25]}
              component="div"
              count={0}
              rowsPerPage={rowsPerPage}
              page={0}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              nextIconButtonProps={{ disabled: true }}
              sx={{
                  color: '#ffffff',
              }}
              classes={{
                root: classes.root,
                actions: classes.actions,
              }}
            />
            :
            <TablePagination
              rowsPerPageOptions={[10, 20, 25]}
              component="div"
              count={0}
              rowsPerPage={rowsPerPage}
              page={0}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              nextIconButtonProps={{ disabled: true }}
            />
          )
      }
      </Paper>
    </Box>
  );
}

const mapStateToProps = (state)=> ({
  tradeHistory: state.tradeLink.tradeHistory,
  user: state.auth.user,
});

export default connect(mapStateToProps, {getTradeHistory})(DataTable);