import React, { useState } from "react";
import { useMaterialUIController } from "context";
import {Select, InputLabel, MenuItem, Input } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import FormControl from "@mui/material/FormControl";
import SettingsIcon from "@mui/icons-material/Settings";
import InputAdornment from "@mui/material/InputAdornment";
import MDButton from "components/MDButton";
import { getTradeLink } from "api/trade";
import { v4 as uuidv4 } from 'uuid';
import { API_ENDPOINTS } from "apiConfig";
import axiosHelper from "../../../Utilities/axiosHelper";
import MDSnackbar from "components/MDSnackbar";

const Test = () => {

    const [open, setOpen] = React.useState(false);

    const [controller] = useMaterialUIController();
	const {
		darkMode,
	} = controller;
    const guid = uuidv4();

    const dispatch = useDispatch();
    React.useEffect(() => {
      dispatch(getTradeLink());
    }, [dispatch]);

    const [successSB, setSuccessSB] = useState(false);
    const [errorSB, setErrorSB] = useState(false);
    const [content, setContent] = useState("");
  
    const closeSuccessSB = () => setSuccessSB(false);
    const closeErrorSB = () => setErrorSB(false);
  
    const renderSuccessSB = (
      <MDSnackbar
        color='success'
        icon='check'
        title='TradeCopy'
        content={content}
        open={successSB}
        onClose={closeSuccessSB}
        close={closeSuccessSB}
        bgWhite
      />
    );
  
    const renderErrorSB = (
      <MDSnackbar
        color='error'
        icon='warning'
        title='TradeCopy'
        content={content}
        open={errorSB}
        onClose={closeErrorSB}
        close={closeErrorSB}
        bgWhite
      />
    );

    const [ testInfo, setTestInfo ] = useState(
        {
            "UID": guid,
            "Side": "buy",
            "Symbol": "",
            "AccountID": "",
            "OrderType": "Market",
            "Qty": 0,
            "StopPrice": 0,
            "TakeProfitPrice": 0,
            "ActionType": ""
        }
    );

    let selectStyle = null;
    if (darkMode) {
        selectStyle = {
            color: '#ffffff'
        };
    }
    
    const tradeAccounts = useSelector((state) => state.profile.userPlatforms || []);
    const tradeLink = useSelector((state) => state.tradeLink?.tradeLink || []);

    const handleSelectionChange = (key, value) => {
        setTestInfo({
            ...testInfo,
            [key]: value
        });
        if (key === 'ActionType') {
            if (value === 'Open') {
                setOpen(false);
                setTestInfo({
                    ...testInfo,
                    UID: guid,
                    [key]: value
                });
            }else{
                setOpen(true);
                setTestInfo({
                    ...testInfo,
                    UID: '',
                    [key]: value
                });
            }
        }
    }

    const handleTest = async() => {
        if (testInfo.ActionType === 'Modify') {
            if (testInfo.UID === '') {
                setContent("Please enter UID !");
                setErrorSB(true);
                return;
            }
            if ((testInfo.StopPrice === 0 || testInfo.StopPrice === '') && (testInfo.TakeProfitPrice === 0 || testInfo.TakeProfitPrice === '')) {
                setContent("Please enter Stop Loss Price or Take Profit Price !");
                setErrorSB(true);
                return;
            }
            if (testInfo.Symbol === '') {
                setContent("Please enter Symbol !");
                setErrorSB(true);
                return;
            }
        }else{
            if (testInfo.ActionType === 'Close' && testInfo.UID === '') {
                setContent("Please enter UID !");
                setErrorSB(true);
                return;
            }
            if (testInfo.Symbol === '' || testInfo.Qty === 0 || testInfo.Qty === "") {
                setContent("Symbol and Quantity are required !");
                setErrorSB(true);
                return;
            }
        }
        let res = await axiosHelper.post(API_ENDPOINTS.trade.test + "?token=" + localStorage.getItem("authToken"), testInfo);
        if (res.status === 200) {
            setContent(res.data);
            setSuccessSB(true);
        }else{
            setContent(res.message);
            setErrorSB(true);
        }
    }

    const handleChange = (e) => {
        setTestInfo({
            ...testInfo,
            [e.target.id]: e.target.value
        })
    }

    return (
        <div>
            {renderSuccessSB}
            {renderErrorSB}
            <div className="flex max-w-[350px] m-auto">
                <FormControl variant='standard' fullWidth style={{ marginBottom: 16 }}>
                    <InputLabel htmlFor='input-with-icon-adornment'>
                        Active Trade Links
                    </InputLabel>
                    <Select
                        value={testInfo?.AccountID}
                        onChange={(e) => handleSelectionChange('AccountID', e.target.value)}
                        displayEmpty
                        sx={selectStyle}
                        label="Action Type"
                        id='AccountID'
                        className="w-full h-10"
                        startAdornment={
                        <InputAdornment position='start'>
                            {darkMode?
                            <SettingsIcon style={{ color: '#ffffff' }}/>
                            :
                            <SettingsIcon />}
                        </InputAdornment>
                        }
                    >
                        {
                            tradeLink?.map((account) => (
                                (account.isActive === true && tradeAccounts.find((p)=> p.id === account.traderAccountInfo.id)?.accountName && tradeAccounts.find((p)=> p.id === account.copyCatAccountInfo.id)?.accountName) && 
                                <MenuItem key={tradeLink.id} value={account.traderAccountInfo.accountId}>
                                    {tradeAccounts.find((p)=> p.id === account.traderAccountInfo.id)?.accountName + " => " + tradeAccounts.find((p)=> p.id === account.copyCatAccountInfo.id)?.accountName}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </div>

            <div className="grid grid-cols-2 max-w-[1200px] m-auto gap-16 mt-5">
                <div>
                    <FormControl variant='standard' fullWidth style={{ marginBottom: 16 }}>
                        <InputLabel htmlFor='input-with-icon-adornment'>
                            Symbol
                        </InputLabel>
                        <Input
                            id='Symbol'
                            className='h-10'
                            type='text'
                            label="Symbol"
                            variant="standard"
                            onChange={handleChange}
                            startAdornment={
                            <InputAdornment position='start'>
                                {darkMode?
                                <SettingsIcon style={{ color: '#ffffff' }}/>
                                :
                                <SettingsIcon />}
                            </InputAdornment>
                            }
                        />
                    </FormControl>
                    <FormControl variant='standard' fullWidth style={{ marginBottom: 16 }}>
                        <InputLabel htmlFor='input-with-icon-adornment'>
                            Action Type
                        </InputLabel>
                        <Select
                            onChange={(e) => handleSelectionChange('ActionType', e.target.value)}
                            value={testInfo?.ActionType}
                            displayEmpty
                            sx={selectStyle}
                            label="Action Type"
                            id='ActionType'
                            className="w-full h-10"
                            startAdornment={
                            <InputAdornment position='start'>
                                {darkMode?
                                <SettingsIcon style={{ color: '#ffffff' }}/>
                                :
                                <SettingsIcon />}
                            </InputAdornment>
                            }
                            >
                                <MenuItem key={"Open"} value={"Open"}>
                                    Open
                                </MenuItem>
                                <MenuItem key={"Close"} value={"Close"}>
                                    Close
                                </MenuItem>
                                <MenuItem key={"Modify"} value={"Modify"}>
                                    Modify
                                </MenuItem>
                        </Select>
                    </FormControl>
                    {
                        open && <FormControl variant='standard' fullWidth style={{ marginBottom: 16 }}>
                            <InputLabel htmlFor='input-with-icon-adornment'>
                                UID
                            </InputLabel>
                            <Input
                                id='UID'
                                className='h-10'
                                label="UID"
                                type='text'
                                variant="standard"
                                value={testInfo.UID}
                                onChange={handleChange}
                                startAdornment={
                                <InputAdornment position='start'>
                                    {darkMode?
                                    <SettingsIcon style={{ color: '#ffffff' }}/>
                                    :
                                    <SettingsIcon />}
                                </InputAdornment>
                                }
                            />
                        </FormControl>
                    }
                    <FormControl variant='standard' fullWidth style={{ marginBottom: 16 }}>
                        <InputLabel htmlFor='input-with-icon-adornment'>
                            Quantity
                        </InputLabel>
                        <Input
                            id='Qty'
                            className='h-10'
                            label="Quantity"
                            type='number'
                            variant="standard"
                            value={testInfo.Qty}
                            inputProps={{ step: 0.0000001 }}
                            onChange={handleChange}
                            startAdornment={
                            <InputAdornment position='start'>
                                {darkMode?
                                <SettingsIcon style={{ color: '#ffffff' }}/>
                                :
                                <SettingsIcon />}
                            </InputAdornment>
                            }
                        />
                    </FormControl>
                </div>
                <div>
                    <FormControl variant='standard' fullWidth style={{ marginBottom: 16 }}>
                        <InputLabel htmlFor='input-with-icon-adornment'>
                            Take Profit Price
                        </InputLabel>
                        <Input
                            id='TakeProfitPrice'
                            className='h-10'
                            label="Take Profit Price"
                            type='number'
                            variant="standard"
                            value={testInfo.TakeProfitPrice}
                            inputProps={{ step: 0.0000001 }}
                            onChange={handleChange}
                            startAdornment={
                            <InputAdornment position='start'>
                                {darkMode?
                                <SettingsIcon style={{ color: '#ffffff' }}/>
                                :
                                <SettingsIcon />}
                            </InputAdornment>
                            }
                        />
                    </FormControl>
                    <FormControl variant='standard' fullWidth style={{ marginBottom: 16 }}>
                        <InputLabel htmlFor='input-with-icon-adornment'>
                            Stop Loss Price
                        </InputLabel>
                        <Input
                            id='StopPrice'
                            className='h-10'
                            label="Stop Loss Price"
                            type='number'
                            variant="standard"
                            value={testInfo.StopPrice}
                            inputProps={{ step: 0.0000001 }}
                            onChange={handleChange}
                            startAdornment={
                            <InputAdornment position='start'>
                                {darkMode?
                                <SettingsIcon style={{ color: '#ffffff' }}/>
                                :
                                <SettingsIcon />}
                            </InputAdornment>
                            }
                        />
                    </FormControl>
                </div>
            </div>
            <div>
                {testInfo.ActionType === 'Open' &&
                    <div className="grid grid-cols-2 gap-16 mt-5 max-w-[600px] m-auto h-10">
                        <MDButton
                            variant='outlined'
                            color='success'
                            onClick={handleTest}
                            fullWidth
                        >
                            BUY
                        </MDButton>
                        <MDButton
                            variant='outlined'
                            color='error'
                            onClick={handleTest}
                            fullWidth
                        >
                            SELL
                        </MDButton>
                    </div>
                }
                {
                    (testInfo.ActionType === "Close" || testInfo.ActionType === "Modify") && 
                    <div className="gap-16 mt-5 max-w-[300px] m-auto h-10">
                        <MDButton
                            variant='outlined'
                            color={ testInfo.ActionType === "Close"? "error" : "info" }
                            onClick={handleTest}
                            fullWidth
                        >
                            {testInfo.ActionType === "Close"? "Close" : "Modify"}
                        </MDButton>
                    </div>
                }
            </div>
        </div>
    );
}


export default Test;