import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import axiosHelper from "../Utilities/axiosHelper";
import { API_ENDPOINTS } from '../apiConfig';
import { resetState } from 'api/auth';

const ProtectedRoute = ({ children, user, resetState }) => {
  const location = useLocation();
  if (location.pathname === '/logout') {
    axiosHelper.get(API_ENDPOINTS.user.logout);
    resetState();
    localStorage.clear();
    return <Navigate to="/login" replace />;
  }
  if (user === false) {
    localStorage.removeItem('authToken');
    return <Navigate to="/login" replace />;
  }
  if (localStorage.getItem('authToken') != null && location.pathname === '/login' && user === true) {
    return <Navigate to="/dashboard" replace />;
  }
  if (localStorage.getItem('authToken') != null && location.pathname === '/login') {
    return <Navigate to="/dashboard" replace />;
  }
  if (localStorage.getItem('authToken') == null && location.pathname !== '/login' && location.pathname !== '/signup' ) {
    localStorage.removeItem('authToken');
    return <Navigate to="/login" replace />;
  }
  if (localStorage.getItem('authToken') != null && location.pathname === '/signup') {
    return <Navigate to="/dashboard" replace />;
  }
  return children;
};

const mapStateToProps = (state) => ({
  user: state.auth.user?.isVerified,
});

export default connect(mapStateToProps, { resetState })(ProtectedRoute);